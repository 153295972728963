@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #1976d2;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
  min-height: 5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/* TODO: Togliere questa cosa molto brutta */
.css-1eamm7b-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
  @apply !border-b-[2px];
}
.css-1eamm7b-MuiInputBase-root-MuiInput-root-MuiSelect-root::after {
  @apply !border-b-[3px];
}

label + .css-1eamm7b-MuiInputBase-root-MuiInput-root-MuiSelect-root {
  @apply !mt-0;
}
